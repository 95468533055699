import React, { FunctionComponent } from 'react';
import './index.scss';

export enum LogoSizes {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  EXTRA_LARGE = 'extra-large',
}

export interface LogoProps {
  size: LogoSizes;
}

const Logo: FunctionComponent<LogoProps> = ({ size }) => {
  return (
    <img
      src="./img/logo.svg"
      alt="Gatitos contra la desigualdad"
      className={`logo logo--${size}`}
    />
  );
};

export default Logo;
