import React, { FunctionComponent } from 'react';

import './index.scss';

import Logo, { LogoSizes } from 'components/shared/Logo';
import Container from 'components/shared/Container';
import Heading, {
  HeadingLevel,
  TextColor,
  BackgroundColor,
} from 'components/shared/Heading';
import Paragraph from 'components/shared/Paragraph';
import Link from 'components/shared/Link';
import CallToAction from 'components/shared/CallToAction';
import Mark from 'components/shared/Mark';
import { MYTHS } from './myths';

export interface MainSectionProps {}

const MainSection: FunctionComponent<MainSectionProps> = () => (
  <div className="main">
    <Container>
      <div className="main__introduction">
        <Heading level={HeadingLevel.ONE}>
          Gatitos contra la desigualdad
        </Heading>

        <Link href="#">
          <Logo size={LogoSizes.MEDIUM} />
        </Link>

        <Heading
          level={HeadingLevel.TWO}
          textColor={TextColor.DARK}
          backgroundColor={BackgroundColor.TRANSPARENT}
        >
          #DeconstruyamosElMitoDeLaMeritocracia
        </Heading>
      </div>

      <Heading
        level={HeadingLevel.THREE}
        textColor={TextColor.WHITE}
        backgroundColor={BackgroundColor.DARK}
      >
        Algunos mitos
      </Heading>
      <ul className="main__myths-list">
        {MYTHS.map((question) => (
          <li key={question.href}>
            <Link {...question}>{question.text}</Link>
          </li>
        ))}
      </ul>

      <Paragraph data-testid="text-invitation">
        Luchemos con <Mark>datitos</Mark> y gatitos <Mark>contra</Mark> los{' '}
        <Mark>mitos</Mark> que legitiman las desigualdades.
      </Paragraph>
      <CallToAction href="#quienes-somos">
        ¿Qué es Gatitos contra la desigualdad?
      </CallToAction>
    </Container>
  </div>
);

export default MainSection;
