import React, { FunctionComponent, ReactChild } from 'react';

import './index.scss';

export interface MarkProps {
  children: ReactChild;
}

const Mark: FunctionComponent<MarkProps> = ({ children }) => (
  <mark className="mark">{children}</mark>
);

export default Mark;
