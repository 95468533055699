import React, { FunctionComponent, ReactChild } from 'react';

import './index.scss';

import Link, { TargetTypes } from 'components/shared/Link';

export interface CallToActionProps {
  children: ReactChild | Array<ReactChild>;
  href: string;
  target?: TargetTypes;
}

const CallToAction: FunctionComponent<CallToActionProps> = ({
  children,
  href,
  target = TargetTypes.SELF,
}) => (
  <Link href={href} target={target} className="call-to-action">
    {children}
  </Link>
);

export default CallToAction;
