import { TargetTypes } from 'components/shared/Link';

export interface Question {
  href: string;
  target: TargetTypes.BLANK;
  text: string;
}

export const MYTHS: Array<Question> = [
  {
    href: 'https://twitter.com/GatitosVsDesig/status/1144068991591288832',
    target: TargetTypes.BLANK,
    text: '¿Crees que el pobre es pobre porque quiere?',
  },
  {
    href: 'https://infogram.com/diadelpagoigualitario-1hke609qoyk365r',
    target: TargetTypes.BLANK,
    text: '¿Piensas que es un mito la brecha salarial de género?',
  },
  {
    href: 'https://twitter.com/gatitosvsdesig/status/1160254704544149504',
    target: TargetTypes.BLANK,
    text: '¿México no es racista, sólo clasista?',
  },
  {
    href: 'https://www.facebook.com/482661808892965/posts/907199916439150/',
    target: TargetTypes.BLANK,
    text: '¿Salvaremos al planeta dejando de tener hijas e hijos?',
  },
];
