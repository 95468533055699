import React, { FunctionComponent, ReactChild } from 'react';

import './index.scss';

export enum TargetTypes {
  /** Opens the linked document in a new window */
  BLANK = '_blank',

  /** Opens the linked document in the same frame as it was clicked (this is default) */
  SELF = '_self',

  /** Opens the linked document in the parent frameset */
  PARENT = '_parent',

  /** Opens the linked document in the full body of the window */
  TOP = '_top',
}

export enum LinkTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export interface LinkProps {
  href: string;
  children: ReactChild | Array<ReactChild>;
  target?: TargetTypes;
  type?: LinkTypes;
  className?: string;
}

const Link: FunctionComponent<LinkProps> = ({
  href,
  children,
  type = LinkTypes.PRIMARY,
  target = TargetTypes.SELF,
  className,
}) => (
  <a
    href={href}
    target={target}
    className={`link link--${type} ${className || ''}`}
    rel={`${target === TargetTypes.BLANK ? 'noopener' : ''}`}
  >
    {children}
  </a>
);

export default Link;
