import React from 'react';

import Main from 'components/Main';
import About from 'components/About';
import Footer from 'components/Footer';

function App() {
  return (
    <div className="App">
      <Main />
      <About />
      <Footer />
    </div>
  );
}

export default App;
