import React, { FunctionComponent } from 'react';

import Container from 'components/shared/Container';
import Paragraph from 'components/shared/Paragraph';
import SocialNetworks from 'components/SocialNetworks';

import './index.scss';

const currentYear = new Date().getFullYear();

export interface FooterProps {}

const Footer: FunctionComponent<FooterProps> = () => (
  <footer className="footer">
    <SocialNetworks />

    <Container>
      {/* @todo add link to INDESIG web site */}
      <Paragraph>
        Gatitos Contra la Desigualdad es uno de los diversos proyectos del
        INDESIG (Instituto de Estudios Sobre Desigualdad, A.C.).
      </Paragraph>

      <Paragraph>
        Copyright ©, {currentYear} | Todos los derechos reservados
      </Paragraph>
    </Container>
  </footer>
);

export default Footer;
