import React, { FunctionComponent, ReactChild } from 'react';
import './index.scss';

export interface ContainerProps {
  children: ReactChild | Array<ReactChild>;
  className?: string;
}

const Container: FunctionComponent<ContainerProps> = ({
  children,
  className,
}) => {
  return <div className={`container ${className || ''}`}>{children}</div>;
};

export default Container;
