import React, { FunctionComponent, ReactChild } from 'react';

import './index.scss';

export enum HeadingLevel {
  ONE = 'one',
  TWO = 'two',
  THREE = 'three',
}

export enum TextColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DARK = 'dark',
  WHITE = 'white',
}

export enum BackgroundColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DARK = 'dark',
  WHITE = 'white',
  TRANSPARENT = 'transparent',
}

export interface HeadingProps {
  level: HeadingLevel;
  children: ReactChild | Array<ReactChild>;
  textColor?: TextColor;
  backgroundColor?: BackgroundColor;
  className?: string;
}

const Heading: FunctionComponent<HeadingProps> = ({
  level,
  children,
  textColor = TextColor.WHITE,
  backgroundColor = TextColor.PRIMARY,
  className,
}) => {
  const levelStringToNumberMap = {
    [HeadingLevel.ONE]: 1,
    [HeadingLevel.TWO]: 2,
    [HeadingLevel.THREE]: 3,
  };

  const headingLevelClassName = `heading--${level}`;
  const textColorClassName = `heading--text-${textColor}`;
  const backgroundColorClassName = `heading--background-${backgroundColor}`;
  const customClassName = className || '';
  const completeClassName = `heading ${headingLevelClassName} ${textColorClassName} ${backgroundColorClassName} ${customClassName}`;

  return React.createElement(
    `h${levelStringToNumberMap[level]}`,
    {
      className: completeClassName,
    },
    children
  );
};

export default Heading;
