import React, { FunctionComponent } from 'react';
import Container from 'components/shared/Container';
import './index.scss';

import Heading, {
  HeadingLevel,
  TextColor,
  BackgroundColor,
} from 'components/shared/Heading';
import Mark from 'components/shared/Mark';
import Paragraph from 'components/shared/Paragraph';

export interface AboutProps {}

const About: FunctionComponent<AboutProps> = () => {
  return (
    <div className="about" id="quienes-somos">
      <Container className="about__content-background">
        <div className="about__content">
          <div className="about__content-section">
            <Heading level={HeadingLevel.TWO}>
              ¿Qué es Gatitos contra la desigualdad?
            </Heading>
            <Paragraph>
              Somos un proyecto de activismo digital que tiene por objetivo
              <Mark>modificar</Mark> las <Mark>representaciones sociales</Mark>
              de las <Mark>desigualdades</Mark> y la
              <Mark> justicia</Mark>, comúnmente sustentadas en premisas
              erróneas, mediante comunicación de datos sencillos, verificables y
              de una manera divertida:
            </Paragraph>
            <Paragraph>
              <Mark>Con muchas fotos de Gatitos. Miau.</Mark>
            </Paragraph>
          </div>

          <div className="about__content-section">
            <Heading
              level={HeadingLevel.THREE}
              textColor={TextColor.WHITE}
              backgroundColor={BackgroundColor.DARK}
            >
              ¿Por qué es importante cómo perciben las personas la desigualdad?
            </Heading>
            <Paragraph>
              La investigación en muchos países ha demostrado lo que se conoce
              como “la paradoja de la desigualdad”: sociedades con niveles más
              altos de desigualdad tienden a percibir menos la amplitud de las
              diferencias en ingresos y riqueza. Además, las personas que
              perciben menos desigualdad son mas propensas a justificarla,
              legitimarla y estar en desacuerdo con la implementación de
              políticas redistributivas.
            </Paragraph>
            <Paragraph>
              De ese modo, con nuestro trabajo buscamos desmontar la narrativa
              meritocrática y aquellas que justifican la desigualdad en sus
              diferentes dimensiones, esperando que de esa manera entre todas y
              todos exijamos mayores acciones desde el gobierno para luchar
              contra la desigualdad.
            </Paragraph>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default About;
