import { SocialNetworkProps } from './SocialNetwork';

export const socialNetworksList: Array<SocialNetworkProps> = [
  {
    name: 'facebook',
    href: 'https://web.facebook.com/gatitosVsDesigualdad/',
  },
  {
    name: 'twitter',
    href: 'https://twitter.com/GatitosVsDesig',
  },
  {
    name: 'instagram',
    href: 'https://www.instagram.com/gatitoscontraladesigualdad/',
  },
];
