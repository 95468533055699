import React, { FunctionComponent } from 'react';

import Container from 'components/shared/Container';
import Heading, {
  HeadingLevel,
  TextColor,
  BackgroundColor,
} from 'components/shared/Heading';
import SocialNetwork from './SocialNetwork';
import { socialNetworksList } from './socialNetworkList';

import './index.scss';

export interface SocialNetworksProps {}

const SocialNetworks: FunctionComponent<SocialNetworksProps> = () => (
  <section className="social-networks">
    <Container>
      <Heading
        level={HeadingLevel.THREE}
        textColor={TextColor.WHITE}
        backgroundColor={BackgroundColor.TRANSPARENT}
        className="social-networks__heading"
      >
        Únete
      </Heading>

      <ul className="social-networks__list">
        {socialNetworksList.map((socialNetworkProps) => (
          <SocialNetwork
            key={socialNetworkProps.name}
            {...socialNetworkProps}
          />
        ))}
      </ul>
    </Container>
  </section>
);

export default SocialNetworks;
