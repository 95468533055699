import React, { FunctionComponent } from 'react';

import './index.scss';

import Link, { TargetTypes } from 'components/shared/Link';

export interface SocialNetworkProps {
  name: string;
  href: string;
}

const SocialNetwork: FunctionComponent<SocialNetworkProps> = ({
  name,
  href,
}) => (
  <li className="social-networks__list-item" key={name}>
    <Link href={href} target={TargetTypes.BLANK}>
      <img
        className="social-networks__list-item-icon"
        src={`./img/social-networks/${name}.png`}
        alt={`ir a ${name}`}
      />
    </Link>
  </li>
);

export default SocialNetwork;
