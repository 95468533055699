import React, { FunctionComponent, ReactChild } from 'react';

import './index.scss';

export interface ParagraphProps {
  children: ReactChild | Array<ReactChild>;
}

const Paragraph: FunctionComponent<ParagraphProps> = ({ children }) => (
  <p className="paragraph">{children}</p>
);

export default Paragraph;
